import React, { useState } from 'react';
import Layout from "./layout"
import styled from "styled-components";
import { Icon, Button, Checkbox } from "@material-ui/core"

const StyledPanel = styled.div`
width: 85%;
margin:auto;
height: 100%;
padding: 20px;
box-sizing: border-box;

& h3{
    font-weight:300;
    font-size: 2em;
}
& .content{
    padding: 36px;
    background: #363636;
    color: gainsboro;   
     border-radius: 4px;
    box-shadow: 1px 3px 3px #00000073;

}

&. info{
text-align:center;
}
& .option{
    & .material-icons{
        font-size: 2.5em;
        color: #727272;
    }
    &.selected{
        & .material-icons{
            font-size: 2.5em;
            color: #c78a40;
        }
    & .body{

        &:hover{
            background: #a18859;
        
        }
        box-sizing:border-box;
        border:1.5px solid #c9922c;
        background: #816849;
    }
}
    & .heading{
        width: 100%;
            text-align:center;
            font-weight:300;
    }
    & .body{
        background: #626262;
        &:hover{
            background: #777777;
    
        }

        & .title{
            font-size: 1.3em;
            text-align:center;
        }
    text-align: left;

        padding: 12px;
        border-radius: 4px;
        box-shadow: 1px 3px 3px #00000073;
    }
    color: #ffffffc4;
    flex-basis: 50%;
    cursor:pointer;
}
& .selection{
    display: flex;
}
`

export default () => {
    const [selected, setSelected] = useState(0)
    return <StyledPanel>
        <h3>Choose a mode</h3>
        <div className="content">
            <div className="info">
                Webcademy's integrated code editor provides a real-world developer environment that utilizes modern JavaScript modules (esm).
                Most lessons will focus on one module / file at a time, but all of your modules
                will eventually form a complete project! <a href="secondary" variant="outlined">learn more</a>
                <br />
                <br />You can either run the project in safe mode or developer mode. (This option can be changed at any time in your project settings)
                <br /><br />
            </div>
            <div className="selection">
                <div onClick={() => {
                    setSelected(1)
                }} className={"option " + (selected == 1 ? "selected" : "")}>
                    <div class="heading"><Icon size="large">construction</Icon>
                    </div><div className="body"><div className="title">Developer Mode</div>
                        Runs the project with all of your previous modules and code. This lets you maintain all of your changes throughout the projects lessons. But it also means that if you write buggy or ugly code, it will carry over into following lessons.
                        You should choose this mode if you are willing to maintain acceptable code throughout the lessons.
                    </div>
                </div>

                <div style={{ width: "24px" }}></div>
                <div onClick={() => {
                    setSelected(0)
                }} className={"option " + (selected == 0 ? "selected" : "")}>
                    <div class="heading"> <Icon size="large">safety_check</Icon>
                    </div><div className="body">
                        <div className="title">Safe Mode </div>
                        Substitute all previous code and modules that aren't part of the current lesson with "safe" backup versions as you progress.
                        This will guarantee that your starting code for each lesson works properly and is well formatted.
                        Choose this mode if you want to experiment with your code without worrying about problems down the line.
                    </div>   </div>
            </div>
        </div>
        <br /><Button variant='contained'>start the lesson!</Button>
    </StyledPanel>
}

/* 

            substitute all previous user-written modules with "safe" versions as you progress
             so you don't have to worry about breaking your project.
           This will also guarantee that your starting code for each lesson will begin properly written and formatted.

  <div className = "option">
            <input checked={true} type="checkbox"></input> safemode will substitute all previous user-written modules with "safe" versions as you progress. These will
            mirror what you wrote in previous lessons, but will be a safe version provided by the lesson creator, so you don't have to worry about breaking your project.
           This will also guarantee that your starting code for each lesson will begin properly written and formatted.
        </div>      

-only in developer mode 
    You already completed this lesson and are running a cached version of your project from that point in time. 
    To preserve the state of your project, changes made here will not affect the main version.
    reset lesson 
    
It this is a first time here, please take a minute to read this short guide:<br />

This will substitute all user-written modules with a default working version that we provide. Just<br/>
        <br/>Aida will try to guide you and prevent you from saving modules that don't pass their unit tests, however
        <br/>in the event that your project breaks due to some change you made in a previous lesson, you can always run your
        <br/>project in safe mode. 
 */