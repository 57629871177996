import React, { useState } from 'react';
import Layout from "./layout"
import styled from "styled-components";

const StyledPanel = styled.div`
width: 100%;
height: 100%;
padding: 20px;
box-sizing: border-box;
& .content{
    padding:12px;
    background: #ffffff2b;
}
`

export default () => <StyledPanel>
    About
    <div className="content">
        <div>
            <br /><h2>Harness the power of code!</h2>
            <br />
            <br />Webcademy provides a real-world coding environment where you will build full projects
            <br />one module at a time in a cohesive set of lessons. Throughout your lessons,
            <br />Webcademy's ai developer assistant, "Aida" will monitor your progress and guide you.
            <br />If you ever got stuck, Aida will point out where your code went wrong and help you through it.
            <br />
            <br /><h3>what makes Webcademy different</h3>
            <br />  Webcademy's learning environment is designed to give you the closest thing to a real developer experience.
            <br />  Lessons are carefully designed to cover all relevant topics through the development of a full project.
            <br />  you decide if and when you want help,
            <br />  .
            <br />
            <br /><h3>aida</h3>
            <br />Aida is able to analyze your program
            <br />to tell you exactly what you did wrong in your code. The ai is able
            <br />to recognize many different approaches to a solution by analyzing the structure of your
            <br />code and running it through a complex testing process using code instrumentation.
            <br />You are free to code in your own way and Aida will usually figure out what you're trying to do.
            <br />If Aida's hint isn't good enough, you can use the "do it for me" button, and aida will complete the current
            <br />step for you!
            <br />
            <br />
            <br /><h3>Webcademy ide</h3>
            <br />Our integrated development environment features an instant live preview and
            supports all modern JavaScript features (modules, es8+ and jsx).
            <br /> The code editor has all the common editor features that you would expect from your favorite text editor.
            If at any time you want to run your project locally or use your own code editor, you can
            download the project in its current state as a zip file.
        </div>
    </div>
</StyledPanel>

/* 
-only in developer mode 
    You already completed this lesson and are running a cached version of your project from a previous point in time. 
    To preserve the state of your project, changes made here will not affect the main version.
    reset lesson 
    
It this is a first time here, please take a minute to read this short guide:<br />

This will substitute all user-written modules with a default working version that we provide. Just<br/>
        <br/>Aida will try to guide you and prevent you from saving modules that don't pass their unit tests, however
        <br/>in the event that your project breaks due to some change you made in a previous lesson, you can always run your
        <br/>project in safe mode. 
 */