import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, ThemeProvider, MuiThemeProvider, Paper, Box, Container, CssBaseline, makeStyles } from '@material-ui/core'
import styled, { keyframes } from "styled-components";
import theme from "./theme"

const Layout = ({ children, setPage }) => {
  function Header() {

    return <StyledHeader>
      <div >
        {'{ '}<span onClick={() => {
          setPage(0)
        }}>Webcademy</span>{' }'}
        <Navigation>
          <div>courses</div>
          <div>pricing</div>
          <div onClick={() => {
            setPage(2)
          }}>about</div>
          <div>forums</div>
        </Navigation>
      </div>
      <div>login</div>
    </StyledHeader>
  }
  return <MuiThemeProvider theme={theme}><StyledLayout>
    <div className='page'>
      <Header /><div style={{ flexGrow: 1 }}>

        {children}
      </div>
      <div style={{
        borderTop: '1px solid #484747',
        color: '#fffb',
        background: '#222',
        padding: '24px'
      }}>
        Copyright © 2022 Webcademy. All rights reserved.
      </div>
    </div>

    <div className='bg' />
  </StyledLayout></MuiThemeProvider>
}

const StyledLayout = styled.div`
  position:relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  
  background: rgb(32 38 45);
  overflow-y: auto;
  overflow-x: hidden;
      height: 100%;
  
      width: 100%;
      color: white;
      text-align: center;
  & .page{
      display:flex;
      flex-direction:column;
    z-index:10;
    height: 100%;
    position:absolute;
    width: 100%;
    scroll:auto;
    overflow:auto;
  }
  & .bg{
    filter: brightness(1.3);
    background:url(./bg1.png);
    background-position: center;
    height: 100%;
    position:absolute;
    width: 100%;
  }
  
  `
const StyledHeader = styled.div`
display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    z-index:99;
color: #fff9;
box-shadow: 0px 3px 8px 0px #00000073;
position: sticky;
padding: 8px;
width: 100%;
background: rgb(38,49,61);
font-size: 1.1em;
font-weight: 500;
text-align: left;
top: 0;
& span{
  color: rgb(193, 148, 64);
  font-weight:400;
}
`


const Navigation = styled.div`
display: inline-flex;
margin-left:24px;
& div{
  text-transform:capitalize;
  font-weight: 400;
  padding:0 12px;
}
`

export default Layout