import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import model from './models/l1.gltf'
let setScene = () => {
  var scene = new THREE.Scene();
  const color = 0x00;  // white
  const near = 10;
  const far = 100;
  scene.fog = new THREE.Fog(color, near, far);

  var size = 10;
  var divisions = 10;
  var gridHelper = new THREE.GridHelper(size, divisions);
  // scene.add(gridHelper);
  var axesHelper = new THREE.AxesHelper(5);
  // scene.add(axesHelper);
  return scene;
};

let setCamera = () => {
  var camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  camera.position.set(0, 1.5, 2.5); // Set position like this
  camera.nextFrame = () => {
    camera.position.x = Math.cos(this.state.x) * 10;
    camera.position.z = Math.sin(this.state.x) * 10;
    camera.lookAt(new THREE.Vector3(0, 0, 0)); // Set look at coordinate like this
  };
  return camera;
};
let loadModel= async()=>{
  return new Promise(function(resolve,reject){

    let loader = new GLTFLoader();
    console.log(loader.load)
    
    loader.load(model, function(gltf){
      console.log('asdf')
      let car = gltf.scene.children[0];
      car.scale.set(.05,.05,.05);
      car.castShadow=true
      console.log(gltf.scene)
      gltf.scene.castShadow=true
      car.children[1].castShadow=true

      car.children[0].children.forEach((e,i)=>{
        e.material.metalness=.5

        e.material.roughness=.1
        e.material.color=(new THREE.Color(0xffffff))
        if(i==1){
          e.material.color=(new THREE.Color(0x444444))
          e.material.roughness=.5
        }
        if(i==0){
          e.material.color=(new THREE.Color(0xff0000))

        }
        e.receiveShadow=true
        e.castShadow=true
      })
      console.log(car)
      
      
      resolve(gltf.scene)
    });
  })
  
  }
let setLight = (x,y,z,color) => {
  var light = new THREE.PointLight(color, 2, 35);
  light.position.set(x,y,z);
  light.castShadow = true;
  light.shadowDarkness = 0.1;

  var sphereSize = 1;
  var pointLightHelper = new THREE.PointLightHelper(light, sphereSize);
  return [light];
};
let setBox = () => {
  var geometry = new THREE.CubeGeometry(1, 1, 1);
  var material = new THREE.MeshStandardMaterial({
    flatShading: false,
    emissive: 0x000000,
    
    // specular: 0xffffff,
    color: 0xffffff,
    roughness:.5,
    metalness:.5
    // envMap:'reflection'
  });
  var cube = new THREE.Mesh(geometry, material);
  cube.castShadow = true;
cube.receiveShadow=true;
  return cube;
};
let setPlane = () => {
  var geometry = new THREE.PlaneGeometry(200, 200, 200);
  var material = new THREE.MeshStandardMaterial({
    
    flatShading: false,
    emissive: 0x000000,
    // specular: 0xffffff,
    color: 0x552244,
    roughness:1,
    metalness:.3
    // envMap:'reflection'
  });
  var cube = new THREE.Mesh(geometry, material);
  cube.receiveShadow=true
cube.rotation.setFromVector3(new THREE.Vector3(  Math.PI / -2, 0,0));
console.log(cube.rotation)
  return cube;
};
let createAnimation = () => {
  let animation = { scene: undefined, animations: [], objects: [] };
  let setScene = scene => {
    animation.scene = scene;
  };
  let getScene = () => {
    return animation.scene;
  };
  let addToScene = function(arg) {
    if (Array.isArray(arg)) {
      arg.forEach(e => {
        animation.objects.push(e);
        animation.scene.add(e);
      });
    }
  //  console.log(arg);

   // console.log(typeof arg);
  else{ console.log(arg)
    animation.scene.add(arg);
  }
  };
  let nextFrame = () => {
    animation.animations.forEach(e => {
      if (e.fn) {
     //   console.log(e);
        e.fn(e.obj);
      }
    });
  };
  let addAnimation = (obj, fn) => {
    animation.animations.push({ obj, fn });
  };
  return {
    addAnimation,
    addToScene,
    nextFrame,
    setScene,
    getScene
  };
};
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { x: 0 };
  }
  componentDidMount() {
    let animation = createAnimation();
    let scene = setScene();
    animation.setScene(scene);

    let camera = setCamera();
    let light = setLight(17, 15, 3,0x8888ff);

    let light2 = setLight(-23, 12, -9,0xFF8800);

    let light3 = setLight(-15, 12, 16,0x00FFFF);

    const lighta = new THREE.AmbientLight(0x555555, .5);

    animation.addToScene(lighta);
    animation.addToScene(light);

    animation.addToScene(light3);

    animation.addToScene(light2);
    loadModel().then((r)=>{
      console.log(r)
      for(let i=0;i<300;i++){
        let clone = r.clone()
        clone.position.x=Math.floor(i/5)*25-64

        clone.position.z=i%5*25-64
        animation.addToScene(clone);

      }
     // animation.addToScene(r);

    })
let plane=setPlane()
animation.addToScene(plane);

    let cubes=[]
    for(let i=0;i<2;i++){
       let cube = setBox();
       cube.index=i
       cubes.push(cube)
       animation.addToScene(cube);
       animation.addAnimation(cube, e => {
        e.position.x = Math.cos((this.state.x*32+i)/14) * (7-i/15);
        e.position.z = Math.sin((this.state.x*32+i)/14) * (7-i/15);
        e.position.y=-70+this.state.x*3+i*2
        e.scale.x=Math.abs(-7+i/50)/3
        e.scale.z=Math.abs(-7+i/50)/3
  });

}
    animation.addAnimation(camera, e => {
           camera.position.x = Math.cos(this.state.x*-1) * 30;
    camera.position.z = Math.sin(this.state.x*-1) * 30;

    camera.position.y=8
    camera.lookAt(new THREE.Vector3(0, 0, 0)); // Set look at coordinate like this
      });

    var renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMapEnabled = true;
    renderer.shadowMapType = THREE.PCFSoftShadowMap;

    this.mount.appendChild(renderer.domElement);

    var animate = () => {
      requestAnimationFrame(animate);
      animation.nextFrame();
      this.setState({ x: this.state.x + 0.01 });
      renderer.render(animation.getScene(), camera);
    };
    animate();
  }
  render() {
    return (
      <>
        <div>{this.state.x}</div>
        <div ref={ref => (this.mount = ref)} />
      </>
    );
  }
}
