import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Test from './test'
import Info from './info'
import About from './about'
import Layout from "./layout"
function Main() {
    const [page, setPage] = useState(0)
    const pages = [
        < App {...{ setPage }} />,
        <Info {...{ setPage }} />,
        <About {...{ setPage }} />
    ]
    return <Layout {...{ setPage }}>
        {pages[page]}
    </Layout>
}
ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA