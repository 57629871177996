import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#aa0000",
    },
    test:{
      main :"#A9783B"
    },
    secondary: {
      main: "#ab7b3d",
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiButton: {
      root: {
        margin: "8px",
        fontSize: "1rem",
      },
    },
    MuiPaper: {
      root: {
        padding: "8px",
        display: "flex",
        flexDirection: "column",
      },
    },
    MuiContainer: {
      root: {
        margin: "16px auto",
      },
    },
  },
});