import{Rating}from '@material-ui/lab'
import Animation from "./animation.js"
import theme from "./theme"
import { AppBar, Toolbar, IconButton, Typography, Button, ThemeProvider, MuiThemeProvider, Paper, Box, Container, CssBaseline, makeStyles } from '@material-ui/core'
// import bg1 from './bg1'
// import styled from "styled-components"
import React ,{useState,useEffect}from 'react'
import styled ,{keyframes}from "styled-components";



const StyledContainer = styled.div`
background: #242424;
box-shadow: 1px 3px 9px 0px #00000033;
background-size: cover;
background-origin: content-box;
background-position: center; 
background-repeat: no-repeat;
    flex-direction: column;
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const StyledHero = styled.div`
    box-shadow: 0px 3px 8px 0px #00000073;
    
padding: 12px 24px;
width: 100%;
background: #00000087;
color: #fffffff2;
text-align: center;
font-size: 1.5em;
font-weight: 100;
`
const StyledCourse = styled.div`
display: flex;
    flex-direction: column;
text-align:left;
background: #434242;
    margin: 4px;
flex-basis: 20%;
    padding: 16px;
    min-width: 200px;
    flex-grow: 1;
    & .course_info_right{
      margin: 8px;
    }
    & .course_info_left{
      margin: 8px;
    }
    & .course_info{
      flex-grow:1;
      justify-content: space-between;
      display:flex;
      flex-direction:column;
    }
    & .course_price{
      font-size: 1.3em;
    }
    & .course_image{
      ${({ image }) => `
       background-image:url("${image}");
       background-size: cover;
       background-origin: content-box;
       background-position: center; 
       background-repeat: no-repeat;
       
      `}
      height: 150px;
    }
    & h3{
          margin: 0;
    font-weight: 100;
    font-size: 2em;
    }
`
const StyledCourseContainer = styled.div`
display: flex;
background: #2f2f2f;
color: #ffffffc9;
flex-wrap: wrap;
`
const StyledCourseOutter = styled.div`
border: 1px solid #494949;
border-radius: 4px;
box-shadow: 2px 1px 4px #0000005e;
margin-bottom: 24px;
background:#2f2f2f;
max-width:950px;
margin:auto;
`

const StyledInfo = styled.div`
font-weight: 100;
color: #ffffffe3;
padding: 24px 162px;
`
function Hero({ children }) {
  return <StyledContainer><StyledHero>
    {children}
  </StyledHero>
    <br /><Button variant="contained" color="secondary">Learn More</Button>

  </StyledContainer>
}

const courseData = [
  {
    name: "React Fundamentals",
    image: 'https://secure.meetupstatic.com/photos/event/d/3/b/6/600_480474198.jpeg',
    description: "A full crash course on all the basic react concepts you need to know. A full crash course on all the basic "
  },
  {
    name: "React Mastery",
    description: "This is placeholder text for a course that hasn't been built yet",
    image: 'https://cdn.slidesharecdn.com/ss_thumbnails/reactjs-191202093535-thumbnail-4.jpg?cb=1602756331'
  },
  {
    name: "material-ui & styled-components",
    description: "this is placeholder text for a course that hasn't been built yet",
    image: 'https://miro.medium.com/max/2600/1*X3GB0FLInAX7VDRz2qJMsg.png'
  },
  // {
  //   name: "intro to JavaScript",
  //   description: "this is placeholder text for a course that hasn't been built yet",
  //   image: 'https://res.cloudinary.com/practicaldev/image/fetch/s--_pyWGSyD--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://thepracticaldev.s3.amazonaws.com/i/w9u60357jk4ozdho7urq.jpg'
  // },
  // {
  //   name: "es6 and modern JavaScript",
  //   description: "this is placeholder text for a course that hasn't been built yet"
  // },
  // {
  //   name: "advanced JavaScript",
  //   description: "this is placeholder text for a course that hasn't been built yet"
  // },
  //   {
  //   name: "lodash",
  //   description: "this is placeholder text for a course that hasn't been built yet"
  // },
  //   {
  //   name: "gatsby",
  //   description: "this is placeholder text for a course that hasn't been built yet"
  // },
  // {
  //   name: "typescript",
  //   description: "this is placeholder text for a course that hasn't been built yet"
  // },
  //   {
  //   name: "CSS fundamentals",
  //   description: "this is placeholder text for a course that hasn't been built yet"
  // },
  //   {
  //   name: "CSS3",
  //   description: "this is placeholder text for a course that hasn't been built yet"
  // },
  //   {
  //   name: "responsive design",
  //   description: "this is placeholder text for a course that hasn't been built yet"
  // },
]
function Courses({ data }) {
  return <StyledCourseOutter>
{/* <h3>Ai Guided Courses: $10 each (limited time offer)</h3> */}
<h3 style={{
  margin:0,
  padding:'24px',
  fontWeight:100,
  color:'#fff9'
}}>Early Release Sale! Courses are 70% off and 1st course is free for a limited time only!!</h3>
    <StyledCourseContainer>

    {data.map((e) => {
      return <Course data={e} />
    })
    }</StyledCourseContainer>
  </StyledCourseOutter>
}
function Course({ data }) {
  const defaults = { price: '$19.99', discount: 80 }
  const { price, name, description, image } = { ...defaults, ...data }
  return <StyledCourse image={image}>
    <div className="course_image" />
        <h3 className="course_name"> {name}</h3>
    <div className="course_info">
      {/* <div className="course_info_left"> */}
        <div className="course_price">Price: {price} <span style = {{textDecoration:"line-through"}}>{"$100.00"}</span></div>
        <div className="course_description"> {description}</div>
       <div>4.6 <Rating name="half-rating" defaultValue={4.5} precision={0.25} /> 28 reviews</div>
      {/* </div> */}
      {/* <div className="course_info_right"> */}
        <div className="course_button">
        </div>
          <Button fullWidth variant="contained" color="secondary">view course</Button>
      </div>
    {/* </div> */}
  </StyledCourse>
}
const Info = () => <StyledInfo>

<br/>
<br/>
<br/>
This is our early release of the platform, so our catalog of courses is still small, but   We will be releasing new courses regularly 


</StyledInfo>
export default ({setPage}) => {
  const [speed,setSpeed] = useState(35)
  useEffect(()=>{
setInterval(() => {
   setSpeed((e)=>Math.max(((e - 1) /1.01) + 1,1.1))
}, 10);
  },[])
  return<>
    {/* <Hero> */}
    <div style = {{
      
      // padding:"0px 210px",
      maxWidth:'950px',margin:'50px auto',
      justifyContent:'space-evenly',
display:"flex",justifyContent:"center",alignItems:"center"
    }}>
      <div style = {{paddingRight:"48px",maxWidth:'500px',}}>
        <h2 style = {{fontWeight: 100}}>Master modern web technology with Webcademy's A.I. tutor, 'Aida'!</h2>
        Aida will guide you in depth through the course's challenges and projects, simplify complex steps and provide an invaluable real-world developer experience!
<br/><br/>
<Button variant = "outlined" color = "secondary" onClick = {()=>{setPage(1)}}>learn more</Button>
      </div>
<Animation speed = {speed} size = {350}/>
      </div>     
<div>
    <Courses data={courseData} />

<div style={{margin:'24px 0'}}>
We will be releasing new courses regularly!
<Button variant = "outlined" color='secondary'> Click here to opt in to new course notifications</Button>
</div>

</div>

  </>
}